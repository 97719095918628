.width {
    width: 90%;
    margin-left: 5%;
    flex: 1;
    padding: 1%;
}

.selectrecipe {
    float: left;
}

.photo {
    margin: 1% 1% 1% 1%;
    /* display: inherit; */
    float: right;
    width: 20%;
}

.forme {
    display: inherit;
    padding: 2% 2% 2% 2%;
}

.back {
    text-align: left;
    width: auto;
    padding: 1%;
    float: left;
}
.steps{
    width:100%;
}
.texte {
    width: 100%;
    margin: 0% 1% 0% 1%;
    text-align: start;
}

.row {
    display: flex;
    align-items: last baseline;
}

.selectitem{
    margin: 5% 0% 2% 0%;
    width: 72%;
}
.imagephoto {
    width: 200px;
    height: 200px;
    margin-top: 2%;
}
.uploader{
    display: inline-flex;
}
.btn12{
    background-color:rgb(212, 234, 234);
    border:none;
    border-radius: 10%;
    
}