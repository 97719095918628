.profil{
    background: #983400;
    padding: 10px;
    border-radius: 10px;
    width: 50%;
    margin-left: 25%;
    margin-top: 20px;
}

.image{
    border-radius: 50%;
    width: 30%;
}

.dialogue{
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    text-align: center;
    padding: 10px;
}

#texte{
    color: #494848;
    text-align: center;
    font-size: 25px;
}

.bouton{
    margin-top: 10px;
    border: none;
    background: #00a2ff;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
}