/* Utility classes for controlling layout and spacing */
.m-1 {
margin: 1%;
}

.m-5 {
  margin: 5px;
}
.p-1 {
  padding: 1%;
}
.ml-5 {
  margin-left: 5px;
}
.mt-2 {
  margin-top: 2%;
}
.p-10 {
  padding: 10px;
}
.p-1{
  padding: 1%;
}
.p-5{
  padding: 4.3%;
}
/* Utility classes for controlling typography */
.text-lg {
  font-size: 1.5rem;
}
.text-sm {
  font-size: 0.875rem;
}
.w-100{
  width: 100%;
}
/* Utility classes for controlling color */
.text-primary {
  color: blue;
}
.bg-secondary {
  background-color: gray;
}

/* Utility classes for controlling design */
.shadow {
  border: 1px solid #ddd;
  border-collapse: collapse;
  box-shadow: 0px 0px 15px #ddd;
}

/* CSS variables for setting theme colors */
:root {
  --success-color: #4caf50;
  /* --links-color: #0000ff; */
}
