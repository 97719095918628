.Sidebar {
  height: 100%;
  float: left;
  position: fixed;
  background-color: #983400;
  width: 17%;
  transition: background-color 0.5s ease;
}

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
  margin-top: 20%;
}

.SidebarList .row {
  width: 100%;
  height: 60px;
  list-style-type: none;
  margin: 0%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Sidebar .row:hover {
  background-color: #0088ff;
  cursor: pointer;
}

.row #icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.row #title {
  flex: 70%;
  text-align: left;
}

.SidebarList #active {
  background-color: black;

}

#logo1 {
  background-color: #983400;
  font-size: 80px;
  color: white;
  color: white;
  padding: 1% 22%;
  border: none;
  width: 100%;

}
h5{
  margin-top: 15%;
  color: white;
  font-weight: 100;
  font-style: italic;
  font-family:cursive;
  font-size: 70%;
}