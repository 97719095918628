.container {
  display: flex;
}

.div1 {
  width: auto;
  padding: 1%;
}

.div2 {
  flex: 1;
  padding: 1%;
}

.div2 form {
  padding: 1%;
  width: 30%;
}

