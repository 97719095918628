.nbr-total{
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
}

.total{
    background: wheat;
    border: none;
    border-radius: 50%;
    box-shadow: 10px 5px 5px #706f6e;
    padding: 20px;
}

.recipes{
    margin-top: 20px;
}

.title{
    font-size: 25px;
    color: #333;
    font-weight: 500;
}

.state{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px;
}

.recette{
    border-radius: 5px;
    background: wheat;
    padding: 20px;
}

.centre{
    text-align: center;
}

.text{
    text-align: start;
}
