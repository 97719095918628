.login-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 20px;
 }

.login-form .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
}

.input {
    margin:auto;
    display:flex;
    align-items:center;
}

.login-form label {
    font-size: 18px;
    margin-right: 10px;
    width: 200px;
}
  
.login-form input {
    padding: 10px;
    width:300px;
    border-radius: 5px;
    border: 1px solid gray;
    flex: 1;
}
  
.login-form button {
    padding: 10px 20px;
    background-color: var(--success-color);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 20px;
    width:100%;
}

#login-button{
    width:auto;
    align-items:center;
    margin-top:10px;
}
#suscribe-button{
    width:auto;
}

.login-form a {
    font-size: 14px;
    text-decoration: none;
    color: var(--links-color);
}
