.table1 {
    width: 90%;
    border: 1px solid #ddd;
    border-collapse: collapse;
    margin-left: 5%;
    box-shadow: 0px 0px 15px #ddd; 
    margin-bottom: 2%;
  }
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  th {
    background-color: #ebe1e1;
  }
  .btn:hover{
    cursor: pointer;
  }
  .btn{
    background-color: white;
    color: #0c1f3d;
    border: none;
  }
  .btn2{
    text-align: right;
  }
  .pagi{
    margin-left: 5%;
  }

/*Search button*/
