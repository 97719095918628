.btn3:hover {
    background-color: #3e8e41;
}

.btn3 {
    background-color: #4CAF50;
    /* green */
    color: white;
    padding: 1% 1%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    margin: 0% 0% 2% 75%;
    width: 15%;
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: end;
}

