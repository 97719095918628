#home-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
  
#nav-bar-content-container {
  width: 83%;
  display: flex;
  flex-direction: column;
  float: right;
}

#cont{
  height: 90%;
  display:flex;
  justify-content:center;
  align-self:center;
  align-items:center;
  max-width: 100vw;
}

.type { 
  margin-left: 5%;
  text-align: left;
  align-self:center;
  margin: 0 auto;
}

.body{
  margin-top: 7%;
  margin-left: 5%;
  height: 100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  align-self:center;
}


#form {
  margin-top: -10% !important;
  display:block;
  width: 300px;
  text-align: center;
}

#formDiv{
  margin-top:5px !important;
  margin-left:-10%;
}


  
#usernameLabel,#emailLabel, #passwordLabel, #confirmPasswordLabel, #confirmedLabel, #blockedLabel {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}
  
.fa-eye{
  position: absolute;
  top: 28%;
  right: 4%;
  cursor:pointer;
  color: lightgray;
}

#password-container{
  position: relative;
  width:300px;
}

#passwordInput{
  width:100%;
  padding:12px 36px 12px 12px;
  padding-right:40px;
}

#confirmPasswordInput{
  width:100%;
  padding:12px 36px 12px 12px;
  padding-right:40px;
}

#confirmPassword-container{
  position:relative;
  width:300px;
}

#usernameInput, #inputmail, #passwordInput,#confirmPasswordInput, #confirmedInput, #blockedInput {
  display:block;
  margin-bottom: 10px;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
  
#submitButton {
  width: 100%;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  background-color:#4CAF50;
  border-radius: 4px;
  cursor: pointer;
}
  
#submitButton:hover {
  background-color: #45a049;
}
  
#blockedInput, #confirmedInput{
  box-sizing:border-box;
  padding:0;
}

#spanEmail{
  position:absolute;
  color:lightgrey;
  top:50%;
  right:5%;
  transform:translateY(-50%);
}

#divEmail{
  position:relative;
  background-color: rgb(255,255,255);
  border-radius: 2px;
}

#showId{
  position:relative;
  align-self:left;
}

#returnArrowDiv{
  position:relative;
  margin-top:-10% !important;
  margin-left:5%;
  top:0;
  left:0;
  z-index:1;
}

#returnArrow{
  position:absolute;
  top:5;
  z-index:1;
  margin-top:7%;
  left:-160%;
}